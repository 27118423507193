$link-color: $blue-200;

// from https://github.com/just-the-docs/just-the-docs/issues/171#issuecomment-538794741
$callouts: (
    info: ($blue-300, rgba($blue-000, .2), 'INFO'),
    warn: ($yellow-300, rgba($yellow-000, .3), 'WARNING'),
    danger: ($red-300, rgba($red-000, .2), 'DANGER'),
    attention: ($yellow-300, rgba($yellow-000, .1), 'attention')
);

@each $class, $props in $callouts {
  blockquote.#{$class} {
    margin-left: 0;
    margin-inline: 0;
  }
  .#{$class} {
    background: nth($props, 2);
    border-left: $border-radius solid nth($props, 1);
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .5rem;
  }
  .#{$class}Title::before {
    color: nth($props, 1);
    content: nth($props, 3);
    display: block;
    font-weight: bold;
    font-size: .75em;
    padding-bottom: .125rem;
  }
}

.logoDiv {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;

  &__logo {
    height: 120px;
    margin-right: 2rem;

    img {
      height: 120px;
    }
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    margin-top: 4rem;

    @media (max-width: 630px) {
      margin-top: 1rem;
    }
    @media (min-width: 800px) and (max-width: 915px) {
      margin-top: 1rem;
    }

    span {
      &:not(:last-child) {
        margin-right: .5em;
      }
      a {
        text-align: center;
      }
    }
  }
}

.img-translation {
  max-width: 600px;

  @media (max-width: 800px) {
    max-width: 100%;
  }
}
.img-pipeline {
  margin-top: 1rem;
}

.language-plaintext, .language-console {
  line-height: 1;
  code {
    line-height: 1;
  }
}

// hide footer so that menu works on mobile
.site-footer {
  display: none;
}

.site-logo {
  height: 3.2rem !important;
}
